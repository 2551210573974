import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

interface Props {
  description?: string;
  lang?: string;
  meta?: meta[];
  title: string;
}

interface meta {
  name: string;
  content: string;
}

const SEO = ({ description, lang, meta, title }: Props) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `,
  );

  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: 'og:type',
          content: 'website',
        },
        {
          name: 'og:url',
          content: 'https://www.hairun-technology.com/',
        },
        {
          name: 'og:site_name',
          content: 'Hairun Technology',
        },
        {
          name: 'og:title',
          content: 'HaiRun Technology - Expert en développement informatique',
        },
        {
          name: 'og:description',
          content: "Une entreprise de services du numérique (ESN), leader en développement d'application web, mobile et logiciel informatique à Madagascar et en France.",
        },
      ].concat(meta || [])}
    />
  );
};

export default SEO;
